<template>
  <div>
    <b-input v-model.lazy="filter" focused autofocus
             :placeholder="placeholder"
             @keydown.enter="searchStudent"
             @keydown.tab="searchStudent"
             @keydown="searchStudent"
    ></b-input>
  </div>
</template>

<script>
import { QR2NIC } from '@/plugins/functions'
export default {
  name: "table_search_field",
  props: [
    'placeholder', 'filter'
  ],
  methods: {
    QR2NIC,
    async searchStudent(event) {
      if(event.key === 'Control' || event.key === 'j') {
        event.preventDefault()
      }

      clearTimeout(this.timeout);

      // Make a new timeout set to go off in 800ms
      this.timeout = await setTimeout(() => {
        if(this.filter) {
          this.filter = this.QR2NIC(this.filter).toString()
          this.$emit('updateFilter', this.filter)
        }
      }, 1000);

    },
  },
}
</script>

<style scoped>

</style>