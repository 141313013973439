<template>
  <div>
    <Breadcrumbs title="Exemptions" main="Master Data"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <!--            <div class="card-header text-lg-right">-->
            <!--              <b-button class="btn-square" variant="success" @click="createRow">New Barrier</b-button>-->
            <!--            </div>-->
            <div class="card-body">


              <div class="row">
                <div class="m-t-6 col-12 col-md-6">
                  <b-input-group>
<!--                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>-->
                    <TableSearchFilter placeholder="Type to Search" label="Student NIC *" :filter="filter" v-on:updateFilter="updateFilter" class="border btn-square" />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                      <b-button @click="fetchData" class="btn btn-square border" variant="light">Refresh</b-button>
                      <b-button v-show="checkUserPermissions(user,['create_barriers'])" @click="showCreateModal" class="btn btn-square border" variant="success">New Exemption</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="m-t-10 col-12 col-md-6 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template class="text-right" #cell(institute_name)="row">{{ row.item.institute.name }}</template>
                  <template class="text-right" #cell(student_name)="row">
                    <strong>{{ row.item.student.first_name }} {{ row.item.student.last_name }}</strong>
                    <p>{{ row.item.student.nic}}</p>
                  </template>
                  <template class="text-right" #cell(details)="row">
                    <p><strong>Class: </strong>{{row.item.class_detail.name}}</p>
                    <p v-if="row.item.start_at !== null">
                      <strong>Start At: </strong>
                      <span>{{new Date(row.item.start_at).toDateString()}}</span>
                    </p>
                    <p v-if="row.item.end_at !== null">
                      <strong>End At: </strong>
                      <span>{{new Date(row.item.end_at).toDateString()}}</span>
                    </p>
                    <p><strong>Creator: </strong>{{row.item.creator.username}}</p>
                  </template>
                  <template class="text-right" #cell(status)="row">
                    <span v-if="row.item.status===0">Active</span>
                    <span v-else>Inactive</span>
                  </template>
                  <template class="text-right" #cell(start_at)="row">

                  </template>
                  <template class="text-right" #cell(end_at)="row">
                    <span v-if="row.item.end_at !== null">{{new Date(row.item.end_at).toDateString()}}</span>
                  </template>
                  <template class="text-right" #cell(created_at)="row">{{new Date(row.item.created_at).toDateString()}}</template>
                  <template #cell(action)="row" class="text-right">
                    <!--                    <b-button class="btn-square" variant="primary">View</b-button>-->
                    <b-button v-show="checkUserPermissions(user,['update_schedule_types'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>
                    <b-button v-show="checkUserPermissions(user,['delete_barriers'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>





              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="form_modal" size="xl" :title="form_modal_title" cancel-variant="default" ok-variant="primary" :ok-title="form_modal_ok_title" @hide="clearFormModals" @ok="submitFormModal">

                <b-form-group v-if="selected.id" label="Id">
                  <b-input v-if="form_modal_id === 'form_modal_create'" v-model="selected.id"></b-input>
                  <b-input v-else v-model="selected.id" readonly></b-input>
                </b-form-group>

                <div class="row">
                  <div class="col-4">
                    <b-form-group label="Student NIC">
                      <b-form-input @change="searchStudentClasses" v-model="student_nic" required autofocus></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-8">
                    <b-form-group v-if="student" label="Student Name">
                      <b-form-input v-model="student_full_name" readonly></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4" v-if="student">
                    <b-form-group label="Institute">
                      <b-form-select class="btn-square border" @change="changedInstitute" v-model="formModal.institute_id" :options="student.institutes" value-field="id" text-field="name">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-8">
                    <b-form-group label="Class">
                      <b-form-select class="btn-square border" :disabled="!class_details.length" v-model="formModal.class_detail_id" :options="class_details" value-field="id" text-field="text">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <template v-if="formModal.student_id">
                  <div class="row">
                    <div class="col-md-4">
                      <b-form-group label="Barrier Type">
                        <b-form-select @change="barrierTypeChanged" class="btn-square border" v-model="barrier_type_id" :options="barrier_types" value-field="id" text-field="text">
                          <template #first>
                            <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div v-if="barrier_type_id===0" class="col-md-4">
                      <b-form-group label="Barrier Date">
                        <b-form-datepicker v-model="formModal.start_at"></b-form-datepicker>
                      </b-form-group>
                    </div>
                    <template v-if="barrier_type_id===1">
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-6">
                            <b-form-group label="Barrier From Date">
                              <b-form-datepicker v-model="formModal.start_at"></b-form-datepicker>
                            </b-form-group>
                          </div>
                          <div class="col-6">
                            <b-form-group label="Barrier To Date">
                              <b-form-datepicker v-model="formModal.end_at"></b-form-datepicker>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>

                </template>

                <b-form-group label="Remark">
                  <b-form-textarea v-model="formModal.remark"></b-form-textarea>
                </b-form-group>

              </b-modal>
              <!-- End Create Modal -->


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableSearchFilter from "@/components/table_search_field";
import {checkUserPermissions} from '@/plugins/functions'
import { mapState } from 'vuex'
import CRUD_API from '@/services/crud'
import API from '@/services/api'
export default {
  name: "exemptions",
  components: { TableSearchFilter },
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      api_base: '/api/backend/pages/exemptions',
      tableFields: [
        { key: 'id', label: 'Exemption ID', sortable: true, },
        { key: 'institute_name', label: 'Institute', sortable: true, },
        { key: 'student_name', label: 'Student', sortable: true, },
        { key: 'details', label: 'Schedule Details', sortable: true, },
        { key: 'remark', label: 'Remarks', sortable: true, },
        { key: 'status', label: 'Status', sortable: true, },
        { key: 'created_at', label: 'Create Date', sortable: true, },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],


      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',
      formModal: {
        id: null,
        index: null,
        institute_id: null,
        student_id: null,
        class_detail_id: null,
        start_at: null,
        end_at: null,
        remark: null,
        type: null,
      },
      student: null,
      student_nic: '',
      barrier_type_id: null,
      class_details: [],
      barrier_types: [
        { id: 0, text: "One Day Free Entrance" },
        { id: 1, text: "Multiple Day Free Entrance" },
        { id: 2, text: "Whole Class" },
      ],
      statusOptions: [
        { value: 0, text: "Active" },
        { value: 1, text: "Inactive" },
      ],
    }
  },
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    created_at(val) {
      return new Date(val).getDate()
    },
    student_full_name() {
      if (this.student) {
        return this.student.first_name + ' ' + this.student.last_name
      }
      return null
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    checkUserPermissions,
    updateFilter(value) {
      this.filter = value
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter
        }
      }).then(function(resp) {
        this.tableRecords = []
        //this.currentPage = resp.data.data.current_page
        //this.perPage = resp.data.data.per_page
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },

    clearFormModals() { this.selected = []; },
    // Create Record Modal
    showCreateModal(button) {
      this.formModal.id = null
      this.formModal.index = null
      this.formModal.student_id = null
      this.formModal.class_detail_id = null
      this.formModal.start_at = null
      this.formModal.end_at = null
      this.formModal.remark = null
      this.form_modal_title = "New Exemption "
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.selected.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      CRUD_API.create(this.api_base, {
        institute_id: this.formModal.institute_id,
        class_detail_id: this.formModal.class_detail_id,
        student_id: this.formModal.student_id,
        start_at: this.formModal.start_at,
        end_at: this.formModal.end_at,
        remark: this.formModal.remark,
        type: this.barrier_type_id
      }).then(() => {
        this.fetchData()
        this.$toasted.success("Exemption added successfully")
      }).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while saving data")
      }).finally(() => {
        //this.fetchData()
      })
    },
    // Edit Record Modal
    showEditModal(index, item, button) {
      this.selected = item
      this.form_modal_title = "Edit xemption "
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal(){
      CRUD_API.update(this.api_base, this.selected.id, this.selected)
          .then((resp) => {
            this.$toasted.success("xemption updated successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while updating data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Delete Record Modal
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal(){
      CRUD_API.delete(this.api_base, this.selected.id)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Exemption removed successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while removing data")
          })
          .finally(() => {
            this.fetchData()
          })
    },

    async getSelectedStudentDetails() {
      this.student = null
      this.formModal.student_id = null
      this.formModal.class_detail_id = null
      this.formModal.start_at = null
      this.formModal.remark = null
      await CRUD_API.index('/api/backend/nic-to-student', {
        params: {
          nic: this.student_nic,
          //has_role: 'developer',
          with_relations: ['institutes']
        }
      }).then((response) => {
        this.student = response.data.data
        this.formModal.student_id = this.student.id || null
      }).catch((error) => {
        console.log(error.message)
        this.$toasted.error(error.message)
      })
    },
    async searchStudentClasses() {
      this.student = null
      this.formModal.student_id = null
      this.formModal.class_detail_id = null
      this.formModal.remark = null
      await CRUD_API.index('/api/backend/nic-to-student', {
        params: {
          nic: this.student_nic,
          //has_role: 'developer',
          with_relations: [
              'institutes', 'roles'//, 'student'
          ]
        }
      }).then((response) => {
        this.student = response.data.data
        this.formModal.student_id = this.student.id || null
        //this.class_details = response.data.data
      }).catch((error) => {
        console.log(error.message)
        this.$toasted.error("No data found")
      }).finally(() => {
        if (this.formModal.student_id) {
          CRUD_API.index('/api/backend/select/classes', {
            params: {
              student_id: this.formModal.student_id
            }
          }).then((response) => {
            this.class_details = response.data.data
          }).catch((error) => {
            console.log(error.message)
          })
        }
      })
    },
    barrierTypeChanged() {
      let today = new Date()
      this.formModal.start_at = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.formModal.end_at = null
    },

    changedInstitute() {
      this.class_details = []
      API.select('classes', {
        institute_id: this.formModal.institute_id,
        student_id: this.student.id,
        select_mode: 'assigned',
        // filter: query
      }).then((response) => {
        this.class_details = response.data.data
      }).catch((error) => {
        console.log(error)
        this.$toasted.error("Error occurred while removing classes.")
      })
    }
  },
}
</script>

<style scoped>

</style>
